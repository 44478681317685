import React, {Component} from 'react'
import Img from "gatsby-image"




function BulletPoint(props){

				console.log("inside bullet point")

				console.log(props)


				
				const panoImg = props.images.edges.find(element => element.node.name === props.data.panoImg).node


				let getPhotoJSX = (item, key, totalPhotos) => {
						const image = props.images.edges.find(element => element.node.name === item.img).node


						const wrapperClass = "img-wrapper-"+totalPhotos
						const imgClass = "bullet-point-image photos-"+totalPhotos+"-"+key
						return (

								<div className={wrapperClass} key={key}>
										<Img className={imgClass} fluid={image.childImageSharp.fluid} />
										<span>{item.caption}</span>
								</div>
							
						)
				}
				console.log(props.data.panoOverlayColor)
				const bannerOverlayStyle = {

												"marginTop":props.data.panoOverlayMarginTop,
												"color":props.data.panoOverlayColor
											};
				console.log(bannerOverlayStyle)
				let photos = props.data.photos.map((item, key) => getPhotoJSX(item,key,props.data.photos.length))
				
				//let photos = getPhotoJSX(props.data.photos[0],0,1)


				let bulletPointPhotosStyle = {
					"width": props.data.imgWidth+"px"
				}

				let textWrapperPhotosStyle = {
					"width": "calc(100% - "+(parseInt(props.data.imgWidth)+25)+"px)" 
				}
				const blurb = {__html: props.data.blurb};
						return (
						<div className="bullet-point generated">
							<h2 className="banner-overlay-txt-mobile">
								{props.data.title}
							</h2>
							<div className="banner-overlay">
							<h2 className="banner-overlay-txt" style={bannerOverlayStyle}>
								{props.data.title}
							</h2>
							<Img fluid={panoImg.childImageSharp.fluid}/>
						</div>
						<div className="text-wrapper" style={textWrapperPhotosStyle}>
							<h3>{props.data.quote}</h3>
							<p dangerouslySetInnerHTML={blurb}>		
							 </p> 
								
						</div>
						<div className="bullet-point-photos" style={bulletPointPhotosStyle}>
							{photos}

						</div>
					</div>
					)
	
}

export default BulletPoint