import React from "react"
import styled from "styled-components"
import { useStaticQuery, graphql, useQuery, Link } from "gatsby"
import Img from "gatsby-image"


import HeaderMinimal from "../../components/header/HeaderMinimal"
import MainMenu from "../../components/header/Menu"
import Footer from "../../components/footer/Footer"

import Sidebar from "../../components/major-pieces/sidebar/Sidebar"
import Subheader from "../../components/major-pieces/subheader/Subheader"
import SubMenu from "../../components/major-pieces/subheader/SubMenu"
import PageBlock from "../../components/major-pieces/pageblock/PageBlock"
import ERIPageBlocks from "../../components/major-pieces/pageblock/ERIPageBlocks"
import BulletPoint from "../../components/st-james-house/BulletPoint"

import "../../styles/global.css"
import "../../styles/global_media.css"
import "../../styles/menu.css"
import "../../styles/big-house.css"

import JSONData from "../../../content/st-james-house/data.json"


function ERI () {
	const query = graphql`
      query {
        allFile(
          sort: { fields: name, order: ASC }
          filter: { relativePath: { glob: "images/pages/st-james-house/*" } }
        ) {
          edges {
            node {
              id
              name
              childImageSharp {

                fluid(quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
    }
    `
    let gqlData = useStaticQuery(query)

	console.log(gqlData)

	let images = gqlData.allFile
	
	const frontPic = images.edges.find(element => element.node.name === 'front_zoomed').node
	const churchPic = images.edges.find(element => element.node.name === 'church').node
	const pottsPic = images.edges.find(element => element.node.name === 'johnsons').node

	const denaliPano = images.edges.find(element => element.node.name === 'denali_pano').node
	const alaska = images.edges.find(element => element.node.name === 'alaska').node

	let bulletPoints = JSONData.bulletPoints.map((item,key) => 
		<BulletPoint data={item} key={key} images={images} />
	)
	

	return(
		<div className="site body">
			<HeaderMinimal />
			<MainMenu metadata="sub-page"/>
			<div className="line-across menu-line-across"></div>
			<main className="site-content eri-colors big-house">
				<div>
					<Subheader data={JSONData.header} />
				</div>
				<div className="content">
{/*					<p className="big-house-welcome-statement">
						We are excited to extend an invitation for young adults to come to
						Eagle River, Alaska and make the St. James House their home for a year starting in
						September 2021. – <b>Bryan and Anna Potts</b>
					</p>*/}
						<div className="experience-alaska top-photos">
							<div className="img-wrapper-2">
								<Img className="church-front" fluid={churchPic.childImageSharp.fluid} />
											
							</div>
							<div className="img-wrapper-2">
								<Img className="big-house-front" fluid={frontPic.childImageSharp.fluid} />
							</div>
							<p className = "intro-paragraph">
								Nestled in the heart of Alaska on the outskirts of the Chugach National Forest, just 30 minutes outside of Anchorage is the St. James House, a ministry of St. John Orthodox Cathedral. The St. James House has traditionally been a place for young adults to grow in their journey towards Christ as they live in community with one another and the wider community. In 2020, we had seven young adults ranging from 18 - 32 years old living together in the midst of the Covid-19 pandemic. It was successful, fruitful and encouraging to have the privilege of living together in community as a house in such an unprecedented time.
								<br/><br/>
								Our goal is to be a home to practice living in a community centered around Christ and the Church, while enjoying the wilderness of Alaska.
							</p>



						</div>

					{bulletPoints}

					<div className="potts">
					<b className="meet-the-potts">Meet the Johnsons</b>
						<div className="potts-pic-wrapper mobile-only">
							<Img className="potts-pic" fluid={pottsPic.childImageSharp.fluid}/>
							<b>
								Eric and Hannah Johnson with their children
							</b>
						</div>
					<div className="potts-paragraph">

For the 2022-2023 year, hospitality will be the theme of the Saint James house. Eric and Hannah Johnson will be the resident overseers of the house. Eric grew up in the community of Saint John’s. Hannah grew up in Southern California and moved up to Alaska in 2007. Eric and Hannah were married in 2010 and have 2 kids/children, Lucia and Ian. They love the outdoors and connecting with people. Their mission this year is to host visitors, community, and youth group functions to grow in their Christian faith. Keep an eye on the Sunday Bulletin to know about specific events in the coming year.

					<br />
					<br />
					

						<Img className="alaska-pic" fluid={alaska.childImageSharp.fluid}/>

												<div className="potts-pic-wrapper">
							<Img className="potts-pic" fluid={pottsPic.childImageSharp.fluid}/>
							<b>
								Eric and Hannah Johnson with their children
							</b>
						</div>


					</div>

					</div>

					<Img className="denali-pano" fluid={denaliPano.childImageSharp.fluid}/>

				</div>
			</main>
			<Footer />		
		</div>
		)
}

export default ERI 


					// <div>
					// 	<img className="church-photo" src={ChurchImg} />

					// 	<img className="front-photo" src={FrontImg} />
					// </div>